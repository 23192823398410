header{
    height: 120vh;
    padding-top: 1rem;
    overflow: hidden;
}
.title{
    padding-top: 1rem;
    height: 200px;
    width: 300px; 
    text-align: center;
    margin: auto;
}

.header__container{
    text-align: center;
    height: 100%;
    position: relative;
    
}

h1,h2{
    color: black;
}
.text-light{
    color: #787878;
    font-size: medium;
}

/*=============CTA====================*/

.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.5rem;
    justify-content: center;

}

/*=============header socials====================*/
.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials a{
    color: black;
}

.header__socials::after{
    content: '';
    width: 1px;
    height: 2rem;
    background:black;
}

/*=============me====================*/
.me{
    /* background:linear-gradient(var(--color-primary),transparent ); */
    width: 20rem;
    height: 20rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    margin-top: 3rem;
    border-radius: 12rem 12rem 12rem 12rem;
    overflow: hidden;
    /* padding: 0 0 0 0; */

}
/*=============SCROLL DOWN====================*/
.scroll__down{
    position: absolute;
    right: -2.3rem;
    bottom: 6rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 1rem;
}

/*===============media queries(medium devices)============================*/
@media screen and (max-width : 1024px) 
{
    header{
        height: 1080px;
    }
}

/*===============media queries(small devices)============================*/
@media screen and (max-width : 600px) {
    header{
        padding-top: 0%;
        padding-left:0px;
        
        height: 540px;
        width: 100%;

    }

    .header__container{
        height: auto;
    }
    .header__socials,
    .scroll__down{
        display: none;
    }

    .me{
        display: none;
    }
    .cta{
        margin-top: 0.5rem;
        display: flex;
        gap: 1.5rem;
        justify-content: center;
    
    }
}