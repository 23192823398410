/* nav{
    background: rgba(0,0,0,0.3);
    width: max-content;
    display: block;
    padding:  0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);


}

nav a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: red;
    font-size: 1.1rem;

}

nav a:hover {
   background: rgba(0,0,0,0.3);
}

.active {
    background: black;
    color: red;
} */

/* //---------------------new css----------// */
 .navbar {
    border:2px solid black;
    background: rgba(190, 188, 188, 0.3);
    width: max-content;
    display: block;
    padding:  0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    bottom: 2rem;
    display: flex;
     flex-direction: row;  
     gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px); 
}
.nav-menu{
    display: flex;
    flex-direction: row;
    gap:5px;
}




 .nav-item a  {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: black;
    font-size: 1.1rem;

}
.nav-item .active{
    background: rgb(97, 90, 90);
}

 .nav-item a:hover {
    background: rgba(255, 0, 0, 0.3);

}



/* @media screen and (max-width:940px) {
    .header {
        max-width: 100%;
        background-color: rgba(0,0,0,.9);
    }

    .header .navbar {
        max-width: 100%;
    }

    .hamburger {
        display: block;
    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 90px;
        flex-direction: column;
        background-color: rgba(0,0,0,.9);
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        margin: 1.5rem 0;
    }

    .header .navbar img {
        width: 150px;
    }
} */
/*===============media queries(small devices)============================*/
@media screen and (max-width : 600px) {
    .navbar{
        display: none;
    }
}