.portfolio__container{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2.5rem;
    justify-content:right;
    
  
    
}

.portfolio__item{
    background:var(--color-bg-variant);
    padding: 2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    height: 350px;
   
    padding-left: 6rem;
}
.portfolio__item:hover{
    border-color: black;
    background: transparent;

}

.portfolio__item h2{
    color:white;
    margin: 1rem 0 2rem;
}

.portfolio__item:hover h2{
    color: black ;
}




.portfolio__item h5{
    margin: 1rem 0 2rem;
}

.portfolio__item:hover h5{
    color: black ;
}

.without_button{
    height: 150px;
}




.portfolio__item-cta{

    margin-bottom: 1rem;
    padding-top: 1.5rem;
    text-align:left;
   
}
.linkdn:hover  {
    color: black;
}


/*===============media queries(medium devices)============================*/
@media screen and (max-width : 1024px) 
{
    .portfolio__container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
   
}

/*===============media queries(small devices)============================*/
@media screen and (max-width : 600px) {
   
    .portfolio__container{
        grid-template-columns: 1fr ;
        gap: 1rem;
        justify-content: center;
    }
    .portfolio__item{
        padding-left:4rem;
    }
    .portfolio__item-cta{
     padding-left: 4.5rem;
    }
    #team{
        padding-left: 0%;
        margin-top: 16px;
        padding-top: 48px;
    }
}
