.about__container{
    display:grid;
    row-gap: 1.5rem;
    /* grid-template-columns: 35% 50%; 
    /* gap: 10%; */}




.about__cards {
    display: grid;
    grid-template-columns: repeat(3,2fr);
    gap: 1.5rem;
    /* row-gap: 1.5rem; */
}

.about_secondrow{
    width:65% ;
    /* position: absolute; */
    margin:auto;
    display: grid;
    grid-template-columns: repeat(2,2fr);
    gap: 1.5rem;
}
.about__card{
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding:1rem;
    text-align: center;
    transition: var(--transition);
    
}

.about__card:hover{
    background: transparent;
    border-color: black;
    cursor: default;
}

.about__card:hover h5{
   
    color: black;
}



.about__icon{
    color: var(--color-primary);
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
}

.about__card h5{
    font-size: 1.0rem;
}



/*===============media queries(medium devices)============================*/
@media screen and (max-width : 1024px) 
{
   .about__container{
    grid-template-columns: 1fr;
    gap: 0;
   }

}

/*===============media queries(small devices)============================*/
@media screen and (max-width : 600px) {
     #data{
        padding-left: 0rem;
        /* padding-right: 16px; */
        /* margin-left:16px; */
        margin-right:16px;
        padding-top: 0px;
     }
    .about__card{
        background: var(--color-bg-variant);
        border: 1px solid transparent;
        border-radius: 1rem;
        padding:1rem;
        text-align: center;
        /* transition: var(--transition); */
        width:120px;
        height:150px;
        
    }
    #data > h2 {
        padding-left: 26px;
    }

    #data > h5{
        padding-left: 26px;
    }
    #data>div{
        padding-left: 10px;
    }

       .about__content{
        text-align: center;
       }
        .about__container{
        position: relative;
        /* padding-right: 25px; */
        row-gap: 20px;
        /* padding-left: -2rem; */
        margin-left: 0;

       } 
       .about__card h5{
        font-size: 0.8rem;
         height: 43.5px;
         overflow: hidden; 

        
    }
       

       .about__cards {
        display: grid;
        grid-template-columns: repeat(3,2fr);
        gap: 1rem;

       
        /* row-gap: 1.5rem; */
    }
    
    .about_secondrow{
        width:65% ;
        /* position: absolute; */
        margin:auto;
        display: grid;
        grid-template-columns: repeat(2,2fr);
        gap: 1.5rem;
        
    }

       /* .about__content p{
        margin: 1.5rem 0;
       } */
}
/* .about__me{
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
     background: linear-gradient(
        45deg,
        transparent,
        var(--color-primary),
        transparent
    ); 
     display: grid;
    place-items: center;
} */

/* .about__me-image{
    border-radius: 2rem;
    overflow: hidden;
     transform: rotate(10deg); 
     transition: var(--transition);
} */
/* .about__me-image:hover{
    transform: rotate(0);
}  */
/* .about__card small{
    font-size: 0.75rem;
    color: var(--color-light);
} */

/* .about__content p{
    margin: 2rem 0 2.6rem;
    color: var(--color-light);

} */