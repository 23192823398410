.container.testimonials__container{
    width:50%;
    height:250px;
    padding-bottom:4rem;
    background: white;
    border-radius: 2rem;
}



.testimonial{
    background: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
        
}
.client__review{
    color: var(white);
    /* font-weight: 300; */
    display: block;
    width: 50;
    margin: 0.8rem auto zero;

}

.client__name{
    color: red;
    font-size:large;
}

.client__name__div{
    margin-top: 2rem;
}

.swiper-pagination-clickable .swiper-pagination-bullet{
    background: var(--color-primary);
}

/*===============media queries(medium devices)============================*/
@media screen and (max-width : 1024px) 
{
    .container.testimonials__container{
        width:60%;
    }
}

/*===============media queries(small devices)============================*/
@media screen and (max-width : 600px) {
    .container.testimonials__container{
        width:var(--container-width-sm);
    }
    #testimonials{
        padding-left: 0%;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 16px;
        padding-top: 48px;
    }

    #testimonials >div{
        width: 100%;
    }

    .client__review{
        
        width: 100%;
        font-size:small;

        
    
    }
    .client__name{
        height: 30px;
        padding-top: 0%;
        padding-bottom: 30px;
        align-items: center;
        
    }
    
    
}
