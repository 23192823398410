.footer{
    
    background: rgba(101, 94, 94, 0.104);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
    border-top:10px solid black;

}



.footer a{
    color: red;
}

.permalinks a{
    color: black;
    font-style: bold;
}

.footer__logo{
    display: inline;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    /* display: inline-block; */
    color: black;
}

.permalinks{
    padding-top:2rem;
    width:80%;
    display: grid;
    color: black;
    grid-template-columns: 1fr 1fr 1fr;
    
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
}

.footer__socials{
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
}

.footer__socials a{
    background: black;
    color: var(--color-white);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
}
.footer__socials a:hover{

    background: transparent;
    color: black;
    border-color: black;

}

.footer__copyrights p{

    margin-bottom: 4rem;
    color: black;
}

/*===============media queries(small devices)============================*/
@media screen and (max-width : 600px) {
    .permalinks{
        flex-direction: column;
        gap:1.5rem;

    }

    .footer__socials{
        margin-bottom: 2.6rem;
    }
    
    .footer{
        margin-top: 0%;
        width: 100%;
        border-top:10px solid rgb(255, 255, 255);
        
        background-color:white;
        
    }
    .permalinks{
        display: flex;
        flex-direction: column;
    }
}
