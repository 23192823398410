@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
margin: 0;
padding: 0;
border: 0;
outline: 0;
box-sizing: border-box;
list-style: none;
text-decoration: none;
}

:root{
  /* --color-bg:#1f1f38; */
  --color-bg:#ffffff;
  --color-bg-variant:#000000;
  --color-primary:#ff0303c6;
  --color-primary-variant: #b40b0b;
  --color-white:#fafafa;
  --color-light:rgba(227, 162, 162, 0.6);
  /* --color-bg-variant:#2c2c6c;
  --color-primary:#4db5ff;
  --color-primary-variant: #4db5ff;
  --color-white:#fff;
  --color-light:rgba(255,255,255,0.6); */
   
  --transition: all 400ms ease;

  --container-width-lg:75%;
  --container-width-md:86%;
  --container-width-sm:90%;
}

html{
  scroll-behavior: smooth;
 
}

::-webkit-scrollbar{
  display: none;
}

body{
  font-family: 'Poppins', sans-serif;
  background:var(--color-bg);
  color: var(--color-white);
  line-height: 1.7;
  width: 100%;
  /* background-image: url(../src/assets/bg-texture.avif); */


}

/*=============general styles==============*/

.container{
  width: var(--container-width-lg);
  margin: 0 auto;

}

h1,h2, h3, h4, h5{
  font-size: 500;
  
}

h1{
  font-size: 2.5rem;
}

section{
  margin-top: 8rem;
  
}

section > h2, section >h5{
  text-align: center;
  color: var(--color-bg-variant);
}

section > h2{
  color: var(--color-primary);
  margin-bottom: 3rem;
}

.text-light{
  color: var(--color-light);
}


a{
  /* color: var(--color-primary); */
  color: black;
  transition: var(--transition);

}

a:hover{
  color: black;
}

.btn{
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover{

  background: var(--color-white);
  color:black;
  border-color: black;
}

.btn-primary{
  background: var(--color-primary);
  color: var(--color-bg);
}

img{
  display: block;
  width: 100%;
  object-fit: cover;
  
}

.headers1{
  color: black;
}

.headers2{
  color:red ;
}

/*===============media queries(medium devices)============================*/
@media screen and (max-width : 1024px) {
  .container{
    width: var(--container-width-md);
  }

  section{
    margin-top: 6rem;
  }
  
}

/*======================media queries(small devices)=================*/
@media screen and (max-width : 600px) {

  .container{
    /* overflow-x:hidden;
    overflow: hidden; */
    width: var(--container-width-sm);
  }
  section{
    margin-top: 1rem;
    padding-left: 32px;
    
  }
  section >h2 {
    margin-top: 1rem;
  }
  section > h2{
    color: var(--color-primary);
    margin-bottom: 1rem;
  }
  
}








